import logo from './logo.svg'
import './App.sass'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>TruDojo.com</h1>
      </header>
      <p>
        Welcome to Truter Dojo Limited, the premier code academy for businesses seeking to elevate the
        expertise of their in-house programmers and contractors. At Truter Dojo, we offer comprehensive
        training in a range of programming languages, including JavaScript, Swift, Python, React,
        and Cloudflare Pages and Workers. We specialize in teaching students how to harness the power
        of cutting-edge AI tools, such as CoPilot and ChatGPT, to accelerate their coding capabilities.
      </p>
      <p>
        We provide a dynamic and interactive learning environment where participants can sharpen their
        coding abilities, stay up-to-date with the latest industry trends, and master the intricacies
        of these essential programming languages. Whether your team needs to deepen their understanding
        of JavaScript frameworks, harness the power of Python for data analysis, or develop robust
        applications with React, Truter Dojo is here to guide their journey.
      </p>
      <p>
        At Truter Dojo, we understand the importance of staying ahead in the rapidly evolving landscape
        of technology. Our curriculum not only equips students with foundational knowledge in JavaScript,
        Swift, Python, React, Cloudflare Pages, and Workers, but also trains them on leveraging AI tools
        to streamline their development process. We guide students on how to effectively utilize
        AI-generated code suggestions, like those provided by GitHub CoPilot, while also teaching them to
        recognize and refine prompts to ensure the production of high-quality code.
      </p>
      <p>
        Moreover, we emphasize the significance of placing the user first in software development. We
        believe that the user's experience of aesthetics, functionality, and performance should be at
        the forefront of every project. Our courses emphasize the principles of user-centered design,
        ensuring that students develop skills to create software that delights users and exceeds their
        expectations. We firmly believe that it is the quality of software, rather than its selling or
        subscription price, that ultimately distinguishes good software from the rest.
      </p>
      <p>
        Join Truter Dojo today and empower your team with the skills they need to thrive in the
        ever-evolving world of programming. Together, we'll unlock their full potential and drive your
        business towards new heights.
      </p>
    </div>
  )
}

export default App
